
import { Options, Vue } from 'vue-class-component';

import * as yup from 'yup';

import requests from '@/requests';

@Options({
  components: {

  },
  props: {

  },
})
export default class AuthIdentify extends Vue {
  public form = { email: '' };

  public schema = yup.object().shape({ email: yup.string().email().required('Email required field') });

  public loader = false;

  public resetPassword(): void {
    this.loader = true;

    // TODO: add error list;
    this.schema.validate(this.form, { abortEarly: false })
      .then((res) => {
        requests.profile.resetPassword(this.form.email)
          .then(() => {
            this.$store.commit('changeData', this.form.email);
            this.$router.push('/confirm-account?type=reset');
          })
          .finally(() => { this.loader = false; });
      })
      .catch(() => { this.loader = false; });

    // .then((res: any) => {
    //   methods.store.changeData({ type: 'password', data: email }, true);
    //   router.push('/confirm-account');
    //   return http.handleSuccess(res.data);
    // })
    // .catch((err: any) => {
    //   const title =
    //     'There is no active user associated with this e-mail address or the password can not be changed';
    //   if (JSON.parse(err.request.response).email[0] === title) {
    //     this.requestConfirmEmail(email);
    //   }
    //   methods.store.changeInputErrors(err.request.response);
    //   return http.handleError(err);
    // })
    // .finally(() => {
    //   this.setDisabledButton(false);
    // });
  }
}
